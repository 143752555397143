import { createSlice } from '@reduxjs/toolkit';

import { AppState } from '@/store';

export interface ThemeModeType {
  mode: 'dark' | 'light';
}

const initialState: ThemeModeType = {
  mode: 'dark',
};
export const themeStoreName = 'theme';
export const selectTheme = (state: AppState) => state.theme.mode;
export const themeSlice = createSlice({
  name: themeStoreName,
  initialState,
  reducers: {
    setTheme(state, { payload }) {
      console.log('setTheme', state, payload);
      state.mode = payload;
    },
  },
});
export const { setTheme } = themeSlice.actions;
export default themeSlice.reducer;
