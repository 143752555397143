import http from './http';

export interface UserType {
  id: number;
  avatar: string;
  nickname: string;
}

export const getPersonalApi = async (): Promise<any> => {
  return http.get('/api/front/personal');
};

export const getSettingsApi = async (): Promise<any> => {
  return http.get('/api/front/settings');
};

export const loginApi = async (date: any): Promise<any> => {
  return http.post('/api/front/login', date);
};


export const getWechatMpCodeApi = async (params?: any): Promise<any> => {
  return http.get('/api/front/login/wechat/mp/qrcode', {params});
};

export const wechatMpLoginApi = async (date: any): Promise<any> => {
  return http.post('/api/front/login/wechat/mp', date);
};



export const logoutApi = async (): Promise<any> => {
  return http.delete('/api/front/logout');
};
