import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { AppState } from '@/store';
import { UserStore } from '@/store/types/user';

import { getPersonalApi } from '@/api/user';

const initialState: UserStore = {
  user: {},
  token: '',
  showLogin: false,
};

export const userStoreName = 'user';

/* 获取用户数据 */
export const fetchUserInfo = createAsyncThunk(
  userStoreName + 'user/getUserDataThunk',
  async () => {
    try {
      return await getPersonalApi();
    } catch (error) {
      throw new Error('slice user userData error');
    }
  }
);

export const userSlice = createSlice({
  name: userStoreName,
  initialState: initialState,
  reducers: {
    setUserInfo(state, { payload }) {
      state.user = payload;
    },
    clearUserInfo(state) {
      state.user = {};
    },
    setToken(state, { payload }) {
      state.token = payload;
    },
    clearToken(state) {
      state.token = '';
    },
    setShowLogin(state, { payload }) {
      state.showLogin = payload;
    },
  },
  extraReducers: (builder) => {
    // 成功
    builder.addCase(fetchUserInfo.fulfilled, (state, { payload }) => {
      return {
        ...state,
        user: payload.result,
      };
    });
    // 错误
    builder.addCase(fetchUserInfo.rejected, (state, action) => {
      const isProd = process.env.NODE_ENV === 'production';
      throw isProd
        ? new Error('slice getUserData error')
        : 'slice getUserData error';
    });
  },
});
export const selectUser = (state: AppState) => state.user.user;
export const selectToken = (state: AppState) => state.user.token;
export const selectShowLogin = (state: AppState) => state.user.showLogin;

export const {
  setUserInfo,
  clearUserInfo,
  setToken,
  clearToken,
  setShowLogin,
} = userSlice.actions;
export default userSlice.reducer;
