import { createSlice } from '@reduxjs/toolkit';

import { AppState } from '@/store';

export interface LocaleModeType {
  lang: 'en-us' | 'zh-cn'
}

const initialState: LocaleModeType = {
  lang: 'zh-cn'
};
export const localeStoreName = 'locale';
export const selectLocale = (state: AppState) => state.locale.lang;
export const localeSlice = createSlice({
  name: localeStoreName,
  initialState,
  reducers: {
    setLocale(state, { payload }) {
      console.log('setLocale', state, payload);
      state.lang = payload;
    },
  },
});
export const { setLocale } = localeSlice.actions;
export default localeSlice.reducer;
