import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import thunk from 'redux-thunk';

import themeStore, { themeStoreName } from './theme';
import userStore, { userStoreName } from './user';
import platformStore ,{platformStoreName} from "@/store/platform";
import localeStore,{localeStoreName} from "@/store/locale";

export type AppState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
const persistConfig = {
  key: 'lateotu',
  storage: storage,
};
const reducers = combineReducers({
  [platformStoreName]: platformStore,
  [themeStoreName]: themeStore,
  [localeStoreName]: localeStore,
  [userStoreName]: userStore,
});

const AppPersistReducer = persistReducer(persistConfig, reducers);

const store = configureStore({
  middleware: [thunk],
  preloadedState: {},
  reducer: AppPersistReducer,
});

export default store;
