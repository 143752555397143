import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';

import {AppState} from '@/store';
import {PlatformStore} from "@/store/types/user";

import {getPlatformInfoApi} from "@/api/platform";

const initialState: PlatformStore = {
    platform: {
        WNPQrcode: "",
        address: "",
        description: "",
        domain: "",
        email: "",
        gwab: "",
        icp: "",
        logo: "",
        phone: "",
        slogan: "",
        title: "",
        twitterUrl: "",
        wechatUrl: "",
        businessCode: "",
        companyName: ""
    }
};
export const platformStoreName = 'platform';
export const selectPlatform = (state: AppState) => state.platform.platform;
export const platformSlice = createSlice({
    name: platformStoreName,
    initialState,
    reducers: {
        setPlatform(state, {payload}) {
            console.log('setPlatform', state, payload);
            state.platform = payload;
        },
    },
    extraReducers: (builder) => {
        // 成功
        builder.addCase(fetchPlatformInfo.fulfilled, (state, {payload}) => {
            return {
                ...state,
                platform: payload.result,
            };
        });
        // 错误
        builder.addCase(fetchPlatformInfo.rejected, (state, action) => {
            const isProd = process.env.NODE_ENV === 'production';
            throw isProd
                ? new Error('slice getUserData error')
                : 'slice getUserData error';
        });
    },
});
export const fetchPlatformInfo = createAsyncThunk(
    platformStoreName + '/getPlatformInfoThunk',
    async () => {
        try {
            return await getPlatformInfoApi();
        } catch (error) {
            throw new Error('slice user userData error');
        }
    }
);

export const {setPlatform} = platformSlice.actions;
export default platformSlice.reducer;
