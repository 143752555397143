import axios from 'axios';

import store from '@/store';
import {clearToken, setShowLogin, setUserInfo} from '@/store/user';
// 根据环境变量修改host
const prodHost = '/';
const devHost = 'http://127.0.0.1:2022';

const appEnv = process?.env.NODE_ENV;
const host = appEnv == 'production' ? prodHost : devHost;

const CODE = {
  LOGIN_TIMEOUT: 1000,
  REQUEST_SUCCESS: 1,
};

export interface ResponseResultType {
  success: boolean;
  code: number;
  message?: string;
  result?: any;
  type?: string;
}

export const refreshPage = () => {
  console.log('refresh');
};
const instance = axios.create({
  baseURL: host,
  timeout: 12000,
  withCredentials: true,
  responseType: 'json',
});

instance.interceptors.request.use((config) => config);

instance.interceptors.request.use((request) => {
  const reduxStore = store.getState();
  const token = reduxStore.user?.token;
  /**
   * 全局拦截请求发送前提交的参数
   * 以下代码为示例，在请求头里带上 token 信息
   */
  if (token) {
    console.log('已登录');
    if (request.headers) {
      request.headers['Authorization'] = `Bearer ${token}`;
    }
  }
  // 是否将 POST 请求参数进行字符串化处理
  if (request.method === 'post') {
    // request.data = qs.stringify(request.data, {
    //     arrayFormat: 'brackets'
    // })
  }
  return request;
});

instance.interceptors.response.use(
  (response) => {
    const { data } = response;
    if (response.status === 200) {
      if (data.code === CODE.REQUEST_SUCCESS) {
        return data;
      }
    }
    // 业务异常
    if (response.status === 200 && response.data.code !== 1) {
      const msg = response.data.message || '未知错误';
      console.log('error response:', msg);
    }
    return data;
  },
  (err) => {
    const { response } = err;
    const { config } = err;
    console.log('请求错误:', err);
    if (err.code === 'ECONNABORTED') {
      return Promise.reject(err);
    }
    if (err.code === 'ERR_NETWORK') {
      return Promise.reject();
    }
    if (!response) {
      return Promise.reject(err);
    }
    if (response.status === 404) {
      return Promise.reject(err);
    }
    // 请求或参数异常
    if (response.status === 400 && response.data.code !== 1) {
      const msg = response.data.message || '请求异常';
      console.log('Request 400 error', msg);
      return response.data;
    }
    // 未授权
    if (response.data.code === 401 || response.status === 401) {
      store.dispatch(setShowLogin(true));
      store.dispatch(setUserInfo({}));
      store.dispatch(clearToken());
      return response.data;
    }
    // 无权访问
    if (response.data.code === 403 || response.status === 403) {
      console.log('Response 403');
    }
    if (response.data.code === 500 || response.status === 500) {
      console.log('Response 500');
    }
    if (!config || !config.retry) return Promise.reject(err);

    config.retryCount = config.retryCount || 0;

    if (config.retryCount >= config.retry) {
      return Promise.resolve();
    }
    config.retryCount += 1;

    const backoff = new Promise((resolve) => {
      setTimeout(() => {
        resolve(null);
      }, config.retryDelay || 1);
    });
    return backoff.then(() => instance(config));
  }
);

export default instance;
