import { StyleProvider } from '@ant-design/cssinjs';
import { AppProps } from 'next/app';
import {Router, useRouter} from 'next/router';
import React, { useEffect } from 'react';
import { Provider } from 'react-redux';
import { persistStore } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';

import '@/styles/globals.css';
import '@/styles/houcloud.css';

import store from '@/store';

const nodeEnv = process?.env.NODE_ENV;

const persist = persistStore(store);

const MyApp = ({ Component, pageProps }: AppProps) => {
  const router = useRouter();
  const consoleStyle = 'font-size: 12px; color: skyblue';
  useEffect(() => {
    if (nodeEnv === 'development') {
      console.log(nodeEnv);
      console.group('%crouter 变化开始 ---->>>', consoleStyle);
      // 路由发生变化时
      Router.events.on('routeChangeStart', (...args) => {
        console.log(
          '%c1.routeChangeStart->路由开始变化,参数为:' + JSON.stringify(args),
          consoleStyle
        );
      });
      // 路由结束变化时
      Router.events.on('routeChangeComplete', (...args) => {
        console.log(
          '%c2.routeChangeComplete->路由结束变化,参数为:' +
            JSON.stringify(args),
          consoleStyle
        );
      });
      // 浏览器history触发前
      Router.events.on('beforeHistoryChange', (...args) => {
        console.log(
          '%c3,beforeHistoryChange->在改变浏览器 history之前触发,参数为:' +
            JSON.stringify(args),
          consoleStyle
        );
      });
      // 路由跳转发生错误时
      Router.events.on('routeChangeError', (...args) => {
        console.log(
          '%c4,routeChangeError->跳转发生错误,参数为:' + JSON.stringify(args),
          consoleStyle
        );
      });
      // 转变成hash路由模式
      Router.events.on('hashChangeStart', (...args) => {
        console.log(
          '%c5,hashChangeStart->hash跳转开始时执行,参数为:' +
            JSON.stringify(args),
          consoleStyle
        );
      });
      Router.events.on('hashChangeComplete', (...args) => {
        console.log(
          '%c6,hashChangeComplete->hash跳转完成时,参数为:' +
            JSON.stringify(args),
          consoleStyle
        );
      });
      console.groupEnd();
    }
  });
  return (
    <Provider  store={store}>
      <PersistGate loading={null} persistor={persist}>
        <StyleProvider hashPriority='high'>
          <Component key={router.asPath} {...pageProps} />
        </StyleProvider>
      </PersistGate>
    </Provider>
  );
};
export default MyApp;
